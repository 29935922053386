import React from "react";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import TeacherForm from "./pages/TeacherForm/TeacherForm";
import TermsAndConditions from "./pages/TermsAndConditions.jsx/TermsAndConditions";
import HomePage from './pages/Home/Homepage';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Toaster />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/register" element={<TeacherForm />} />
          <Route path="/termandcondition" element={<TermsAndConditions />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;

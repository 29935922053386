import React from "react";
import "./HomePage.css";
import { Link } from "react-router-dom";
import logo from '../../assets/img/studyhelpline.png'
const HomePage = () => {
    return (
        <div className="homepage">
            <nav>
                <div className="menu">
                    <i className="ri-menu-line"></i>
                </div>
                <div className="company" >
                    <img className="company-logo" src={logo} alt="" />
                </div>
                <div className="links">
                    <Link to=''>Find a tutor</Link>
                    <Link to=''>How it works</Link>
                    <Link to=''>Prices</Link>
                    <Link to=''>Resources</Link>
                    <Link to=''>For schools</Link>
                    <Link to='/register'>Become a tutor</Link>
                </div>
                <div className="mobilelinks" >
                    <Link to="/register">Become a tutor</Link>

                </div>

                <div className="contact">
                    {/* <a href="tel:+911234567890">+911234567890</a> */}
                    <a href="https://wa.me/9250192507" target="_blank" rel="noreferrer">Contact us </a>

                    <button className="signbtn"><Link to="/termandcondition" >TermsAndConditions</Link></button>
                </div>
            </nav>

            <main>
                <div className="mainleft">
                    <h1>Find a Tutor and Let Your Child Shine
                    </h1>
                    <p>
                        We can't stop you worrying about your child. But our expert tutors
                        can help their grades and confidence soar - and help you worry a
                        little less.
                    </p>
                    <div className="search">
                        <div className="searchbox">
                            <input placeholder="Search a subject" type="text" />
                            <i className="ri-search-line"></i>
                        </div>
                        <button   ><a href="https://wa.me/9250192507" target="_blank" rel="noreferrer"  > Get Started</a></button>
                    </div>
                </div>

                <div className="mainright"></div>
            </main>

            <div className="strip">
                <p>
                    Excellent{" "}
                    <span>
                        <i className="ri-star-fill"></i>
                    </span>
                    <span>
                        <i className="ri-star-fill"></i>
                    </span>
                    <span>
                        <i className="ri-star-fill"></i>
                    </span>
                    <span>
                        <i className="ri-star-fill"></i>
                    </span>
                </p>
                <h2>426,535+ 5-star reviews</h2>
                <h2>1500 schools trust us</h2>
                <h2>30+ subjects available</h2>
                <h2>255,203+ students</h2>
            </div>

            <div className="pick">
                <h3>Pick a subject to get started</h3>
                <div className="flexbtns">
                    <button>Maths</button>
                    <button>English</button>
                    <button>Physics</button>
                    <button>Biology</button>
                    <button>Science</button>
                    <button>Spanish</button>
                    <button>French</button>
                    <button>German</button>
                    <button>History</button>
                    <button>Music</button>
                    <button>Phsycology</button>
                    <button>Politics</button>
                    <button className="allsub">All subjects</button>
                </div>
                <h3>Start learning in 3 steps</h3>
                <div className="cards">
                    <div className="card">
                        <div className="imgwrapper">
                            <img
                                src="https://www.mytutor.co.uk/_next/image?url=%2Fimages%2Fshare-your-goals.png&w=384&q=80"
                                alt=""
                            />
                        </div>
                        <h4>Share your goals</h4>
                        <p>
                            Let us know what type of tutoring you need and when, so we can
                            find you the right matches for your needs.
                        </p>
                    </div>

                    <div className="card">
                        <div className="imgwrapper">
                            <img
                                src="https://www.mytutor.co.uk/_next/image?url=%2Fimages%2Ffind-the-perfect-fit.png&w=384&q=80"
                                alt=""
                            />
                        </div>
                        <h4>Find the perfect fit</h4>
                        <p>
                            Browse our tutors, get in touch, and book as many free meetings as
                            you need to find the tutor they click with most.
                        </p>
                    </div>

                    <div className="card">
                        <div className="imgwrapper">
                            <img
                                src="https://www.mytutor.co.uk/_next/image?url=%2Fimages%2Flet-the-learning-begin.png&w=384&q=80"
                                alt=""
                            />
                        </div>
                        <h4>Let the learning begin</h4>
                        <p>
                            Once you’ve found the right fit, it’s time to book your lessons
                            and start learning with MyTutor.
                        </p>
                    </div>
                </div>
                <div className="handpicked">
                    <div className="handpickedleft">
                        <h4>1.	Tell Us What You Need</h4>
                        <p>
                            o	Let us know your tutoring requirements, and we’ll match you with the perfect tutor. Browse through profiles of certified professionals, and submit your request. Our Education Consultant will contact you with tutor profiles and fees tailored to your budget
                        </p>
                    </div>

                    <div className="handpickedright">
                        <img
                            src="https://www.mytutor.co.uk/landing/brand-image-for-feature-1.svg"
                            alt=""
                        />
                    </div>
                </div>
                <div className="handpicked trustedby">
                    <div className="handpickedleft">
                        <h4>2.	Get Matched with Your Ideal Tutor</h4>
                        <p>
                            o	Based on your needs, we’ll find the ideal tutor. We’ll share their qualifications and experience, so you can make an informed decision. Talk to your tutor before the first class to discuss learning methods, class timings, and more.
                        </p>
                    </div>

                    <div className="handpickedright">
                        <img
                            src="https://www.mytutor.co.uk/landing/brand-image-for-feature-2.svg"
                            alt=""
                        />
                    </div>
                </div>
                <div className="handpicked helpfrom">
                    <div className="handpickedleft">
                        <h4>3.	Begin Your Learning Journey</h4>
                        <p>
                            o	Once you’ve finalized a tutor, it’s time to start your lessons. We’ll set up your schedule at a time that suits you. No extra fees – you only pay for the sessions you take.
                        </p>
                    </div>

                    <div className="handpickedright">
                        <img
                            src="https://www.mytutor.co.uk/landing/brand-image-for-feature-3.svg"
                            alt=""
                        />
                    </div>
                </div>
                <div className="handpicked ourinter">
                    <div className="handpickedleft">
                        <h4>•	Higher Grades, Top Rankings</h4>
                        <p>
                            o	Every year, our students consistently top their classes and exams, including CBSE, ICSE, and IB boards. Our tutors use adaptive methods to provide a solid support system that enhances learning and builds confidence.
                        </p>
                    </div>

                    <div className="handpickedright">
                        <img
                            src="https://www.mytutor.co.uk/landing/brand-image-for-feature-4.svg"
                            alt=""
                        />
                    </div>
                </div>
            </div>

            {/* <div className="strip2">
                <h2>As featured in</h2>
                <img src="https://cdn.mytutor.co.uk/images/sundaytimes_48.svg" alt="" />
                <img src="https://cdn.mytutor.co.uk/images/guardian_48.svg" alt="" />
                <img
                    src="https://www.mytutor.co.uk/_next/image?url=https%3A%2F%2Fcdn.mytutor.co.uk%2Fimages%2Fuploads%2Fbbc_image_resized.gif&w=256&q=75"
                    alt=""
                />
                <img src="https://cdn.mytutor.co.uk/images/sky_48.svg" alt="" />
            </div> */}

            <div className="faqcontainer">
                <img src="https://www.mytutor.co.uk/landing/brand-shape6.svg" alt="" />
                <img src="https://www.mytutor.co.uk/landing/brand-shape5.svg" alt="" />
                <div className="faq">
                    <h2>Read our FAQs</h2>
                    <button className="accordion acc1">What makes a good tutor ?</button>
                    <div className="panel panel1">
                        <p>
                            Subject knowledge is a must, but being a good tutor is really
                            about explaining difficult concepts in a way that's clear,
                            engaging and personalised for each student. You can have three
                            degrees in Physics, but that doesn't mean you'll be good at
                            explaining it at GCSE level! Tutors who can empathise with their
                            students and help them build confidence help teens unleash their
                            potential, and we see amazing results in just a few lessons. Our
                            tutors are from top UK unis, and because they're just a few years
                            older, they can explain things in a way that teens find relatable.
                            We interview all of our tutors, and only the friendliest and most
                            knowledgeable make it on to our platform. We're very picky about
                            it - just 1 in 8 applicants make the cut.
                        </p>
                    </div>

                    <button className="accordion acc2">Which tutor is right for you ?</button>
                    <div className="panel panel2">
                        <p>
                            Before you look for a tutor, it's helpful to have a really clear
                            idea of exactly where your child needs help - whether with a
                            specific English Literature text, one area of Maths or their exam
                            technique - and filter your choices accordingly. If you're not
                            sure where they need to focus, having a chat with them or their
                            teacher can help you work out the best place to start. In a free
                            meeting, you can then ask the tutor any questions you like and see
                            how well they get on with your child before deciding to book.
                        </p>
                    </div>

                    <button className="accordion acc3">
                        Why is online tutoring important ?
                    </button>
                    <div className="panel panel3">
                        <p>
                            Online tutoring gives kids the chance to learn at their own pace
                            and in a way that matches their learning style. Teens are often
                            too shy to put their hand up in class - especially if they're
                            struggling. The reassurance of one-to-one tutoring means they can
                            ask all the questions they want, and go over topics as much as
                            they need until they get it.
                        </p>
                    </div>

                    <button className="accordion acc4">
                        What are the benefits of online tutoring ?
                    </button>
                    <div className="panel panel4">
                        <p>
                            One-to-one tutoring lets kids unleash their potential. Worried
                            about learning gaps? We'll fill them in. No tutors in your area?
                            We've got you covered. No academic confidence? No problem.
                            Whatever your child needs help with, their tutor will guide them
                            through tricky topics and boost their self-belief. With the
                            personalised one-to-one support from their tutor, your child can
                            get the grades they deserve.
                        </p>
                    </div>

                    <button className="accordion acc5">How much does a tutor cost ?</button>
                    <div className="panel panel5">
                        <p>
                            Our tutors set their own prices based on their experience and
                            qualifications, starting from £22/hour at GCSE level. Most of our
                            tutors charge between £22 and £30 an hour. You can see all the
                            tutors who match your budget with the handy price filter on our
                            Find a tutor page.
                        </p>
                    </div>

                    <button className="accordion acc6">How to find a tutor ?</button>
                    <div className="panel panel6">
                        <div>
                            Finding a tutor is easy. Whatever you need, you can search and
                            filter by subject, level, price, experience and more.
                            <ol>
                                <li>
                                    View our tutors, and narrow your search by subject, academic
                                    level or price. Or if you'd like expert help, book a call with
                                    our free tutor matching service.
                                </li>
                                <li>
                                    Compare their profiles to learn about their background,
                                    experience and availability. Don't forget to take a peek at
                                    their reviews from parents and students!
                                </li>
                                <li>
                                    Message a tutor (or two, or three) and book your free meeting
                                    at a time that suits you.
                                </li>
                                Book a lesson and pay as you go. There are no hidden costs and
                                you can skip or cancel any time.
                            </ol>
                        </div>
                    </div>

                    <button className="accordion acc7">How do online lessons work ?</button>
                    <div className="panel panel7">
                        <p>
                            We have our own online lesson space with video chat, messaging and
                            an interactive whiteboard - this makes it easy for students and
                            tutors to talk to each other, discuss tricky concepts and do
                            practice questions together. With the live video chat, they can
                            have a natural back-and-forth conversation - just like on
                            FaceTime, Whatsapp and other apps teens use all the time.
                        </p>
                    </div>
                </div>
            </div>

            <div className="strip3">
                <Link to='/'>Maths</Link>
                <Link to='/'>Biology</Link>
                <Link to='/'>Chemistry</Link>
                <Link to='/'>Physics</Link>
                <Link to='/'>Science</Link>
                <Link to='/'>English</Link>
                <Link to='/'>German</Link>
                <Link to='/'>Spanish</Link>
                <Link to='/'>French</Link>
                <Link to='/'>Economics</Link>
                <Link to='/'>Physycology</Link>
                <Link to='/'>History</Link>
            </div>

            <div className="rescontainer">
                <div className="resources">
                    <div className="resleft">
                        <h2>Free study resources</h2>
                    </div>

                    <div className="resright">
                        <p>
                            Over a million students use our free resources to help them with
                            their homework.
                        </p>
                        <button>Explore resources</button>
                    </div>
                </div>


            </div>

            <div className="book">
                <img
                    className="bookimg1"
                    src="https://www.mytutor.co.uk/landing/brand-shape3.svg"
                    alt=""
                />
                <img
                    className="bookimg2"
                    src="https://www.mytutor.co.uk/landing/brand-shape4.svg"
                    alt=""
                />
                <h2>
                    Book a free meeting with a tutor today and find out how they can help
                    your child
                </h2>
                <button>Find a tutor</button>
            </div>

            <footer>
                <div className="upperfooter">
                    <div className="col">
                        <h3>Company Information</h3>
                        <Link to='/'>Careers</Link>
                        <Link to='/'>Blog</Link>
                        <Link to='/'>Subject answers</Link>
                        <Link to='/'>Become a tutor</Link>
                        <Link to='/'>Schools</Link>
                        <Link to='/'>Safeguarding policy</Link>
                        <Link to='/'>FAQ'S</Link>
                        <Link to='/'>Using the Online Lesson Space</Link>
                        <Link to="/termandcondition">TermsAndConditions</Link>
                        <Link to='/'>Sitemap</Link>
                    </div>

                    <div className="col">
                        <h3>Popular Requests</h3>
                        <Link to='/'>Maths tutor</Link>
                        <Link to='/'>Chemistry tutor</Link>
                        <Link to='/'>Physics tutor</Link>
                        <Link to='/'>Biology tutor</Link>
                        <Link to='/'>English tutor</Link>
                        <Link to='/'>GCSE tutor</Link>
                        <Link to='/'>A level tutor</Link>
                        <Link to='/'>IB tutor</Link>
                        <Link to='/'>Physics & Maths tutor</Link>
                        <Link to='/'>Chemistry & Maths tutor</Link>
                        <Link to='/'>GCSE Maths tutor</Link>
                    </div>

                    <div className="col">
                        <h3>We are here to help</h3>
                        <div className="contactus">
                            <i className="ri-contacts-book-3-line"></i>
                            <Link to="/">Contact us</Link>
                        </div>
                        <div className="whatsapp">
                            <i className="ri-whatsapp-line"></i>
                            <Link to='/'>Message us on Whatsapp</Link>
                        </div>
                        <div className="phone">
                            <i className="ri-phone-fill"></i>
                            <a href="tel:+919250192507">+91 9250192507</a>
                        </div>
                        <div className="social">
                            <i className="ri-facebook-circle-fill"></i>
                            <i className="ri-instagram-fill"></i>
                            <i className="ri-linkedin-box-fill"></i>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    );
};

export default HomePage;
